import {
  Box,
  Button,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { axiosBackend } from '../../../../../api/Configuration';
import FullPageLoading from '../../../../../components/FullPageLoading';
import Paginate from '../../../../../components/Paginate';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { hasFiltering } from '../../../../../helpers/isFiltering';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import AddPoleSplit from '../AddPoleSplit';
import ConfirmDeletionModal from '../ConfirmDeletionModal';

interface IRevenueSplitPoleView {
  idGroup: number;
  poleName: string;
  userName: string;
  productName: string;
  status: 'ATIVO' | 'INATIVO';
}

interface IRevenueSplitPole {
  revenueSplitPoleView: IRevenueSplitPoleView[];
  total: number;
  page: number;
  per_page: number;
}

export default function PoleSplit() {
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [searchText, setSearchText] = useState(urlSearchParams.get('search'));
  const [searchStatus, setSearchStatus] = useState(urlSearchParams.get('status'));
  const [splitIdToDelete, setSplitIdToDelete] = useState<number>(0);

  const {
    isOpen: isOpenAddPoleSplit,
    onOpen: onOpenAddPoleSplit,
    onClose: onCloseAddPoleSplit,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();

  const {
    data: response,
    loading,
    fetchData: getSplitPoles,
  } = useFetch<UnificadaFront.ResponseJSON<IRevenueSplitPole>>({
    url: `/revenue-split-pole?${urlSearchParams.toString()}`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const poleSplit = response?.data?.revenueSplitPoleView;
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const pageCount = response?.data?.total / response?.data?.per_page;
  const shouldShowPagination = !loading && pageCount > 1;

  const isFiltering = hasFiltering(urlSearchParams);
  const isNotFoundVisible = !loading && !poleSplit?.length && isFiltering;
  const isEmpty = !loading && !poleSplit?.length && !isFiltering;

  const hiddenTable = loading || !poleSplit?.length || (!poleSplit?.length && searchText !== '');

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  function handleChangeFilterStatus(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setSearchStatus(value);
  }

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchText(value);
  }

  const handleSearchChange = useCallback(
    (search: string) => {
      if (!search) return history.push('/revenue-split/pole');

      urlSearchParams.set('page', '1');
      urlSearchParams.set('search', search);

      return history.push({
        pathname: '/revenue-split/pole',
        search: urlSearchParams.toString(),
      });
    },
    [history, urlSearchParams]
  );

  function clearSearch() {
    setSearchText('');
    handleSearchChange(''); // Chamada da função handleSearchChange ao limpar o texto
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Escape') {
      clearSearch();
    }
  }

  function editSplit(id: number) {
    history.push(`${url}/${id}`);
  }

  async function updateRevenueSplitGroup(id: number, status: 'ATIVO' | 'INATIVO') {
    try {
      let newStatus = '';

      if (status === 'ATIVO') {
        newStatus = 'INATIVO';
      } else {
        newStatus = 'ATIVO';
      }

      const payload = {
        status: newStatus,
      };

      await axiosBackend().patch(`/revenue-split-group/${id}`, payload);
      Toast('Compartilhamento atualizado!', 'success');
      getSplitPoles();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function onOpenConfirmDeletion(id: number) {
    setSplitIdToDelete(id);
    onOpenConfirmDelete();
  }

  const { isLoading: isSubmitting, handleSubmit: deleteSplitGroup } = useHandleSubmit({
    url: `/revenue-split-group/${splitIdToDelete}`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Compartilhamento excluído com sucesso!',
      callback() {
        getSplitPoles();
        onCloseConfirmDelete();
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await deleteSplitGroup();
  }

  async function onFilter() {
    if (!searchStatus && !searchText) return history.push('/revenue-split/pole');

    if (searchText) urlSearchParams.set('search', searchText);
    if (!searchText) urlSearchParams.delete('search');

    if (searchStatus) urlSearchParams.set('status', searchStatus);
    if (!searchStatus) urlSearchParams.delete('status');

    history.push({ search: urlSearchParams.toString() });
  }

  return (
    <>
      <Stack spacing="30px">
        <HStack width="full" justifyContent="space-between" alignItems="center">
          <HStack>
            <InputGroup width={{ base: 'full', lg: '300px' }} size="sm" hidden={isEmpty}>
              <InputLeftElement
                pointerEvents="none"
                children={<AiOutlineSearch color="gray" size="20px" />}
              />
              <Input
                type="text"
                name="search"
                placeholder="Buscar por Polo, Gestor ou Produto"
                value={searchText}
                onChange={handleChangeSearch}
                onKeyDown={handleKeyDown}
                focusBorderColor="primary.400"
                borderRadius={5}
              />
            </InputGroup>

            <Box hidden={isEmpty}>
              <Select
                size="sm"
                name="searchStatus"
                focusBorderColor="primary.500"
                placeholder="Todos os status"
                width="200px"
                borderRadius={5}
                value={searchStatus}
                onChange={handleChangeFilterStatus}
              >
                <option value="ATIVO">Ativo</option>
                <option value="INATIVO">Inativo</option>
              </Select>
            </Box>

            <Button
              hidden={isEmpty}
              size="sm"
              color="secondary.500"
              colorScheme="primary"
              onClick={onFilter}
            >
              Filtrar
            </Button>
          </HStack>

          <Button
            size="sm"
            colorScheme="primary"
            color="secondary.500"
            onClick={() => onOpenAddPoleSplit()}
          >
            Novo compartilhamento
          </Button>
        </HStack>

        <Heading hidden={!isEmpty} fontSize="md" fontWeight="400" color="#CBC9C9">
          Compartilhe a receita desse produto de acordo com a origem do aluno. O seu aluno poderá
          selecionar a qual polo ele pertence, e a divisão ser realizada.
        </Heading>

        <Text fontSize="sm" textAlign="center" color="#CBC9C9" hidden={!isEmpty}>
          Você não possui compartilhamento de receitas do tipo polo.
        </Text>

        <Heading fontSize="sm" textAlign="center" color="#CBC9C9" hidden={!isNotFoundVisible}>
          Você não possui compartilhamento com esse nome, produto ou status
        </Heading>
      </Stack>

      <TableContainer hidden={hiddenTable}>
        <Table variant="unstyled" style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
          <Thead bgColor="primary.500" color="secondary.500" borderRadius={5} h={45}>
            <Tr>
              <Th fontSize="14px" textTransform="none" fontWeight={400} borderLeftRadius={5}>
                <Text>Nome do Polo</Text>
              </Th>
              <Th fontSize="14px" textTransform="none" fontWeight={400}>
                <Text>Gestor de Polo</Text>
              </Th>
              <Th fontSize="14px" textTransform="none" fontWeight={400}>
                <Text>Produto</Text>
              </Th>
              <Th fontSize="14px" textTransform="none" fontWeight={400}>
                <Text>Status</Text>
              </Th>
              <Th
                fontSize="14px"
                textTransform="none"
                fontWeight="400px"
                borderRightRadius={5}
                width="20px"
              >
                <Text>Ações</Text>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {poleSplit?.map(revenueSplit => (
              <Tr bgColor="rgba(32, 33, 35, 0.05)" key={revenueSplit.idGroup}>
                <Td
                  borderLeftRadius={5}
                  onClick={() => editSplit(revenueSplit.idGroup)}
                  cursor="pointer"
                >
                  <Text color="#20212380">{revenueSplit.poleName}</Text>
                </Td>
                <Td
                  borderLeftRadius={5}
                  onClick={() => editSplit(revenueSplit.idGroup)}
                  cursor="pointer"
                >
                  <Text color="#20212380">{revenueSplit.userName}</Text>
                </Td>
                <Td
                  borderLeftRadius={5}
                  onClick={() => editSplit(revenueSplit.idGroup)}
                  cursor="pointer"
                >
                  <Text color="#20212380">{revenueSplit.productName}</Text>
                </Td>
                <Td borderLeftRadius={5}>
                  <Text color="#20212380">{revenueSplit.status}</Text>
                </Td>
                <Td borderLeftRadius={5}>
                  <Text color="#20212380">
                    <Menu>
                      <MenuButton
                        bgColor="#20212320"
                        p="2px"
                        borderRadius={5}
                        color="rgba(32, 33, 35, 0.5)"
                      >
                        <HiDotsHorizontal size="18px" />
                      </MenuButton>

                      <MenuList>
                        <MenuItem
                          color="gray.500"
                          onClick={() =>
                            updateRevenueSplitGroup(revenueSplit.idGroup, revenueSplit.status)
                          }
                        >
                          {revenueSplit.status === 'INATIVO' ? 'Ativar' : 'Inativar'}
                        </MenuItem>
                        <MenuItem
                          onClick={() => onOpenConfirmDeletion(revenueSplit.idGroup)}
                          fontWeight="semibold"
                          color="red.500"
                        >
                          Excluir
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {shouldShowPagination && (
          <Paginate
            pageCount={pageCount}
            initialPage={currentPage}
            onPageChange={handlePageChange}
            mt={{ base: 8, xl: 4 }}
          />
        )}
      </TableContainer>

      <FullPageLoading isLoading={loading} />

      <AddPoleSplit
        isOpen={isOpenAddPoleSplit}
        onClose={onCloseAddPoleSplit}
        getSplitPoles={getSplitPoles}
      />

      <ConfirmDeletionModal
        isOpen={isOpenConfirmDelete}
        onClose={onCloseConfirmDelete}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </>
  );
}
