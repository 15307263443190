import { IconButton, Menu, MenuButton, MenuItem, MenuList, Td, Tr } from '@chakra-ui/react';
import { memo } from 'react';
import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Plan } from '../../..';
import convertToBrazilianCurrency from '../../../../../../../../helpers/convertToBrazilianCurrency';

interface PlanTableRowProps {
  plan: Plan;
}

function PlanTableRow({ plan }: PlanTableRowProps) {
  return (
    <Tr color="gray.700" sx={{ td: { p: 6 } }}>
      {/* NOME */}
      <Td maxWidth="393px">{plan.name}</Td>

      {/* QTD. DE ASSINATURAS */}
      <Td maxWidth="190px">{plan.numberOfSignatures}</Td>

      {/* QTD. DE CANCELAMENTOS */}
      <Td maxWidth="190px">{plan.numberOfCancellations}</Td>

      {/* FATURAMENTO */}
      <Td maxWidth="10rem">{convertToBrazilianCurrency(plan.invoicing)}</Td>

      <Td textAlign="center" maxWidth="5.9375rem">
        <Menu size="sm" arrowPadding={0}>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<MoreIcon size="1.125rem" />}
            w="27px"
            h="24px"
            borderRadius={5}
            bgColor="#2021231F"
            _hover={{ bgColor: '#b6b5b5' }}
            _active={{ bgColor: '#b6b5b5' }}
          />

          <MenuList
            sx={{
              '.chakra-menu__menuitem': {
                fontWeight: 'medium',
                fontSize: 'xs',
                color: '#20212380',
                _hover: { bgColor: '#2021230D' },
                _focus: { bgColor: '#2021230D' },
              },
            }}
          >
            <MenuItem
              as={Link}
              to={{
                pathname: `/recurrence/subscriber-management/${plan.id}`,
                state: { planName: plan.name },
              }}
            >
              Gestão de assinantes
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default memo(PlanTableRow);
