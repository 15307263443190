import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { memo } from 'react';
import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link, useRouteMatch } from 'react-router-dom';
import convertToBrazilianCurrency from '../../../../../../helpers/convertToBrazilianCurrency';
import { Plan } from '../../types';
import {
  formatCreatedAt,
  getPaymentMethodsText,
  getRecurrenceText,
  getStatusText,
} from '../../utils';

interface CheckoutSettingsTableRowProps {
  plan: Plan;
}

function PlanTableRow({ plan }: CheckoutSettingsTableRowProps) {
  const { url } = useRouteMatch();

  return (
    <Tr color="gray.700" sx={{ td: { p: 6 } }}>
      <Td maxWidth="7.5rem" whiteSpace="normal" fontWeight="medium">
        {plan.pagarMePlanId}
      </Td>

      <Td maxWidth="13.5625rem">
        <Tooltip label={plan.name} isDisabled={plan.name.length < 20}>
          <Text isTruncated>{plan.name}</Text>
        </Tooltip>
      </Td>

      <Td maxWidth="7.375rem">
        <Text color="gray.700">{formatCreatedAt(plan.createdAt)}</Text>
      </Td>

      <Td maxWidth="9.6875rem">{getRecurrenceText(plan.recurrence)}</Td>

      <Td maxWidth="9.6875rem">{getPaymentMethodsText(plan.paymentMethod)}</Td>

      <Td maxWidth="9.6875rem">{convertToBrazilianCurrency(plan.value)}</Td>

      <Td maxWidth="9.6875rem" textTransform="capitalize">
        {getStatusText(plan.status)}
      </Td>

      <Td textAlign="center" maxWidth="5.9375rem">
        <Menu size="sm" arrowPadding={0}>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<MoreIcon size="1.125rem" />}
            w="27px"
            h="24px"
            borderRadius={5}
            bgColor="#2021231F"
            _hover={{ bgColor: '#b6b5b5' }}
            _active={{ bgColor: '#b6b5b5' }}
          />

          <MenuList
            sx={{
              '.chakra-menu__menuitem': {
                fontWeight: 'medium',
                fontSize: 'xs',
                color: '#20212380',
                _hover: { bgColor: '#2021230D' },
                _focus: { bgColor: '#2021230D' },
              },
            }}
          >
            <MenuItem as={Link} to={`${url}/edit/${plan.id}/payment`}>
              Editar
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default memo(PlanTableRow);
