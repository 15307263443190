export function getStatusText(status: string) {
  return {
    trialing: 'Em período de teste',
    paid: 'Pago',
    pending_payment: 'Pendente de pagamento',
    unpaid: 'Não pago',
    canceled: 'Cancelado',
    ended: 'Finalizado',
  }[status];
}
