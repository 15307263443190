import { Avatar, HStack, Skeleton, SkeletonText, Text, VStack } from '@chakra-ui/react';
import { IRecurrencePlanResponse } from '../..';

interface ProductProps {
  plan: IRecurrencePlanResponse;
  isLoading: boolean;
}

export default function Plan({ plan, isLoading }: ProductProps) {
  const isLoaded = !isLoading;

  return (
    <HStack w="full" align="flex-start" spacing={{ base: 3, xl: 4 }}>
      <Skeleton startColor="default.400" endColor="default.400" isLoaded={isLoaded}>
        <Avatar
          src=""
          name={plan?.name}
          borderRadius="base"
          background="default.500"
          color="white"
          boxSize={{ base: '4.25rem', xl: '5.875rem' }}
        />
      </Skeleton>

      <SkeletonText noOfLines={2} isLoaded={isLoaded} w="full" skeletonHeight={4}>
        <VStack spacing={0} justify="start" align="start">
          <Text
            color="#202123"
            fontSize="20px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            {plan?.name}
          </Text>
        </VStack>
      </SkeletonText>
    </HStack>
  );
}
