import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { Heading } from '../../../../components/Heading';
import PlanSplit from './PlanSplit';
import PoleSplit from './PoleSplit';
import StandardSplit from './StandardSplit';

export type RevenueSliptType = 'DEFAULT' | 'POLE' | 'AFFILIATE' | 'RECURRENCE_DEFAULT';
type SplitType = 'standard' | 'plan' | 'pole';

function getDefaultIndex(splitType: SplitType): number {
  switch (splitType) {
    case 'standard':
      return 0;
    case 'plan':
      return 1;
    case 'pole':
      return 2;
    default:
      return 0;
  }
}

export default function RevenueSplit() {
  const history = useHistory();

  const { type } = useParams<{ type: SplitType }>();

  function handleTabsChange(index: number) {
    switch (index) {
      case 0:
        return history.push(`/revenue-split/standard`);
      case 1:
        return history.push('/revenue-split/plan');
      case 2:
        return history.push('/revenue-split/pole');
      default:
        return history.push(`/revenue-split/standard`);
    }
  }

  return (
    <Box>
      <Stack width="full" justifyContent="space-between">
        <Heading fontSize={{ base: 'xl', lg: '2xl' }}>Compartilhamento de Receita</Heading>

        <Tabs
          isLazy
          colorScheme="primary"
          onChange={handleTabsChange}
          defaultIndex={getDefaultIndex(type)}
        >
          <TabList>
            <Tab>Padrão</Tab>
            <Tab>Planos</Tab>
            <Tab>Polos</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <StandardSplit />
            </TabPanel>
            <TabPanel>
              <PlanSplit />
            </TabPanel>
            <TabPanel>
              <PoleSplit />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
}
