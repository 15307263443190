import {
  Button,
  HStack,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Users from '../../../../../assets/icons/users.svg';
import FullPageLoading from '../../../../../components/FullPageLoading';
import Paginate from '../../../../../components/Paginate';
import { hasFiltering } from '../../../../../helpers/isFiltering';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import AddStandardSplit from '../AddStandardSplit';
import ConfirmDeletionModal from '../ConfirmDeletionModal';

interface IRevenueSplitDefaultView {
  idGroup: number;
  courseName: string;
  usersCount: number;
}
interface IRevenueSplit {
  revenueSplitDefaultView: IRevenueSplitDefaultView[];
  total: number;
  page: number;
  per_page: number;
}

export default function StandardSplit() {
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams: URLSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [searchText, setSearchText] = useState(urlSearchParams.get('search'));
  const [delay, setDelay] = useState<NodeJS.Timeout | null>(null);
  const [splitIdToDelete, setSplitIdToDelete] = useState<number>(0);

  const {
    isOpen: isOpenAddStandardSplit,
    onOpen: onOpenAddStandardSplit,
    onClose: onCloseAddStandardSplit,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();

  const {
    data: response,
    loading,
    fetchData: getStandardSplit,
  } = useFetch<UnificadaFront.ResponseJSON<IRevenueSplit>>({
    url: `/revenue-split?${urlSearchParams.toString()}`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const standardSplit = response?.data?.revenueSplitDefaultView;
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const pageCount = response?.data?.total / response?.data?.per_page;
  const shouldShowPagination = !loading && pageCount > 1;

  const isFiltering = hasFiltering(urlSearchParams);
  const isNotFoundVisible = !loading && !standardSplit?.length && isFiltering;
  const isEmpty = !loading && !standardSplit?.length && !isFiltering;

  const hiddenTable =
    loading || !standardSplit?.length || (!standardSplit?.length && searchText !== '');

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  const handleSearchChange = useCallback(
    (search: string) => {
      if (!search) return history.push('/revenue-split');

      urlSearchParams.set('page', '1');
      urlSearchParams.set('search', search);

      return history.push({
        pathname: '/revenue-split',
        search: urlSearchParams.toString(),
      });
    },
    [history, urlSearchParams]
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;

    setSearchText(text);

    // Limpa o timeout anterior, se existir
    if (delay) clearTimeout(delay);

    // Define um novo timeout para chamar a função handleSearchChange após 500ms
    const newTimeout = setTimeout(() => handleSearchChange(text), 500);

    setDelay(newTimeout);
  }

  function clearSearch() {
    setSearchText('');
    handleSearchChange(''); // Chamada da função handleSearchChange ao limpar o texto
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Escape') {
      clearSearch();
    }
  }

  function editSplit(id: number) {
    history.push(`${url}/${id}`);
  }

  async function onOpenConfirmDeletion(id: number) {
    setSplitIdToDelete(id);
    onOpenConfirmDelete();
  }

  const { isLoading: isSubmitting, handleSubmit: deleteSplitGroup } = useHandleSubmit({
    url: `/revenue-split-group/${splitIdToDelete}`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Compartilhamento excluído com sucesso!',
      callback() {
        getStandardSplit();
        onCloseConfirmDelete();
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await deleteSplitGroup();
  }

  return (
    <>
      <Stack spacing="30px">
        <HStack width="full" justifyContent="space-between" alignItems="center">
          <InputGroup width={{ base: 'full', lg: '300px' }} hidden={isEmpty}>
            <InputLeftElement
              pointerEvents="none"
              children={<AiOutlineSearch color="gray" size="20px" />}
            />
            <Input
              height="40px"
              size="sm"
              type="text"
              name="search"
              placeholder="Buscar por Produto ou Usuário"
              value={searchText}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              focusBorderColor="orange.400"
              borderRadius={5}
            />
          </InputGroup>

          <Stack
            direction={{ base: 'column', md: 'row', lg: 'row' }}
            width={{ base: 'full', md: 'auto', lg: 'auto' }}
          >
            <Button
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              onClick={() => onOpenAddStandardSplit()}
            >
              Novo compartilhamento
            </Button>
          </Stack>
        </HStack>

        <Heading hidden={!isEmpty} fontSize="md" fontWeight="400" color="#CBC9C9">
          Compartilhe a receita dos seus produtos com quem contribuiu na construção! Adicione
          quantos você quiser, mas fique atento a porcentagem que será concedida!
        </Heading>

        <Text fontSize="sm" textAlign="center" color="#CBC9C9" hidden={!isEmpty} margin="10">
          Você não possui compartilhamento de receitas.
        </Text>

        <Text
          fontSize="sm"
          textAlign="center"
          color="#CBC9C9"
          hidden={!isNotFoundVisible}
          margin="10"
        >
          Você não possui compartilhamento com esse nome ou produto.
        </Text>
      </Stack>

      <TableContainer hidden={hiddenTable}>
        <Table variant="unstyled" style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
          <Thead bgColor="primary.500" color="secondary.500" borderRadius={5} height={45}>
            <Tr>
              <Th fontSize="14px" textTransform="none" fontWeight="400" borderLeftRadius={5}>
                <Text>Produto</Text>
              </Th>
              <Th fontSize="14px" textTransform="none" fontWeight="400">
                <Text>Usuários no compartilhamento</Text>
              </Th>

              <Th
                fontSize="14px"
                textTransform="none"
                fontWeight="400"
                borderRightRadius={5}
                width="20px"
              >
                <Text>Ações</Text>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {standardSplit?.map(revenueSplit => (
              <Tr bgColor="rgba(32, 33, 35, 0.05)" key={revenueSplit.idGroup}>
                <Td
                  borderLeftRadius={5}
                  onClick={() => editSplit(revenueSplit.idGroup)}
                  cursor="pointer"
                >
                  <Text color="#20212380">{revenueSplit.courseName}</Text>
                </Td>

                <Td
                  borderLeftRadius={5}
                  onClick={() => editSplit(revenueSplit.idGroup)}
                  cursor="pointer"
                >
                  <Stack direction="row">
                    <Image src={Users}></Image>
                    <Text color="#20212380">
                      {revenueSplit.usersCount}{' '}
                      {revenueSplit.usersCount === 1 ? 'Usuário' : 'Usuários'}
                    </Text>
                  </Stack>
                </Td>

                <Td borderLeftRadius={5}>
                  <Text color="#20212380">
                    <Menu>
                      <MenuButton
                        bgColor="#20212320"
                        padding="2px"
                        borderRadius={5}
                        color="rgba(32, 33, 35, 0.5)"
                      >
                        <HiDotsHorizontal size="18px" />
                      </MenuButton>

                      <MenuList>
                        <MenuItem
                          onClick={() => onOpenConfirmDeletion(revenueSplit.idGroup)}
                          fontWeight="semibold"
                          color="red.500"
                        >
                          Excluir
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {shouldShowPagination && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          onPageChange={handlePageChange}
          mt={{ base: 8, xl: 4 }}
        />
      )}

      <FullPageLoading isLoading={loading} />

      <AddStandardSplit
        isOpen={isOpenAddStandardSplit}
        onClose={onCloseAddStandardSplit}
        getStandardSplit={getStandardSplit}
      />

      <ConfirmDeletionModal
        isOpen={isOpenConfirmDelete}
        onClose={onCloseConfirmDelete}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </>
  );
}
