import {
  chakra,
  Grid,
  GridItem,
  GridProps,
  Image,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';

import createProduct from '../../../../../assets/icons/redirectionIcons/Open-book.svg';
import buildYourWebsite from '../../../../../assets/icons/redirectionIcons/Desktop-computer.svg';
import UpMyClasses from '../../../../../assets/icons/redirectionIcons/Outbox-tray.svg';
import InviteUsers from '../../../../../assets/icons/redirectionIcons/Bust-in-silhouette.svg';
import RegisterBankDetails from '../../../../../assets/icons/redirectionIcons/Bank.svg';
import RevenueSplit from '../../../../../assets/icons/redirectionIcons/Dollar-banknote.svg';
import CreateBlog from '../../../../../assets/icons/redirectionIcons/Memo.svg';
import ExploreProluno from '../../../../../assets/icons/redirectionIcons/Magnifying-glass-tilted-right.png';

function CustomRadio(props) {
  const { image, text, ...radioProps } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } = useRadio(radioProps);

  return (
    <GridItem w="full" h="full" minH={'5.5rem'}>
      <chakra.label {...htmlProps} cursor="pointer" w="full" h="full">
        <input {...getInputProps({})} hidden />
        <Stack
          {...getCheckboxProps()}
          h="full"
          direction="column"
          border="2px"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          borderColor={state.isChecked ? '#EB7129' : 'gray.200'}
          rounded={6}
        >
          <Image w={25} h={37} src={image} {...getLabelProps()} />
          <Text fontSize="md" fontWeight="semibold" maxWidth="80%" lineHeight="20px">
            {text}
          </Text>
        </Stack>
      </chakra.label>
    </GridItem>
  );
}

interface PathCardsProps extends GridProps {
  onPathChange: (path: string) => void;
}

export default function PathCards(props: PathCardsProps) {
  const options = [
    {
      text: 'Criar um Produto',
      image: createProduct,
      path: '/courses/new',
    },
    {
      text: 'Construir o Seu Site',
      image: buildYourWebsite,
      path: '/page-builder',
    },
    {
      text: 'Subir minhas aulas',
      image: UpMyClasses,
      path: '/video-library',
    },
    {
      text: 'Convidar Usuários',
      image: InviteUsers,
      path: '/user-base',
    },
    {
      text: 'Cadastrar Dados Bancários',
      image: RegisterBankDetails,
      path: '/profile/bank-accounts',
    },
    {
      text: 'Compartilhar Receita',
      image: RevenueSplit,
      path: '/revenue-split/standard',
    },
    {
      text: 'Criar blog',
      image: CreateBlog,
      path: '/internal-blog',
    },
    {
      text: 'Explorar a Proluno',
      image: ExploreProluno,
      path: '/home',
    },
  ];

  const handleChange = value => {
    props.onPathChange(value);
  };

  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: '',
    onChange: handleChange,
  });

  return (
    <Grid
      {...getRootProps()}
      {...props}
      templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
      gap={2}
    >
      {options.map(option => {
        return (
          <CustomRadio
            key={option.text}
            image={option.image}
            text={option.text}
            {...getRadioProps({ value: option.path })}
          />
        );
      })}
    </Grid>
  );
}
