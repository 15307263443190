import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';
import CopyButton from './CopyButton';
import { PlanManagementEditDescriptionProps } from './types';
import usePlanManagementEditDescription from './usePlanManagementEditDescription';

export default function PlanManagementEditDescription({
  planData,
}: PlanManagementEditDescriptionProps) {
  const {
    form,
    isSubmitting,
    formValidation,
    checkoutLink,
    handleRadioChange,
    handleCancelButtonClick,
    onSubmit,
  } = usePlanManagementEditDescription({
    planData,
  });

  return (
    <>
      <VStack
        as="form"
        onSubmit={onSubmit}
        noValidate
        id="planManagementEditDescriptionForm"
        spacing={4}
        mt={10}
        sx={{
          '.chakra-checkbox__label, .chakra-form__label': { fontSize: 'sm', color: '#202123' },
          '.chakra-radio__control': { mt: '1.5px' },
          input: {
            _placeholder: { color: '#0000005C', fontsize: 'md' },
          },
        }}
      >
        <FormControl isInvalid={formValidation?.willUsePlanPage?.isInvalid}>
          <FormLabel>Você utilizará uma página de plano?</FormLabel>

          <RadioGroup
            value={form.willUsePlanPage ? 'true' : 'false'}
            onChange={value =>
              handleRadioChange('willUsePlanPage', value === 'true' ? true : false)
            }
          >
            <VStack
              sx={{
                color: '#202123',
                fontSize: 'sm',
                fontWeight: 'medium',
                span: { fontSize: 'sm' },
              }}
            >
              <Radio value="false" width="100%" alignItems="start" colorScheme="primary">
                Não, quero que o usuário siga direto para o checkout.
              </Radio>

              <Radio value="true" width="100%" alignItems="start" colorScheme="primary" isDisabled>
                Sim
              </Radio>
            </VStack>
          </RadioGroup>

          <FormErrorMessage>{formValidation?.willUsePlanPage?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Link do seu checkout</FormLabel>

          <InputGroup borderRadius="base">
            <Input
              value={checkoutLink}
              color="#959595 !important"
              borderRadius="base"
              isTruncated
            />

            <InputRightAddon
              bg="#2021231A"
              children={<CopyButton textToCopy={checkoutLink} />}
              p={0}
            />
          </InputGroup>
        </FormControl>
      </VStack>

      <HStack as="footer" w="full" justify="end" spacing={3} mt={10}>
        <Button
          onClick={handleCancelButtonClick}
          variant="ghost"
          background="gray.200"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isDisabled={isSubmitting}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          form="planManagementEditDescriptionForm"
          colorScheme="primary"
          color="secondary.500"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isLoading={isSubmitting}
        >
          Avançar
        </Button>
      </HStack>
    </>
  );
}
