import { FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../../utils/scrollToInvalidInput';
import { PlanResponse } from '../types';
import { RecurrencePlanDescriptionForm } from './types';

const INITIAL_STATE = {
  willUsePlanPage: false,
} as RecurrencePlanDescriptionForm;

export interface usePlanManagementEditDescriptionProps {
  planData: PlanResponse;
}

export default function usePlanManagementEditDescription({
  planData,
}: usePlanManagementEditDescriptionProps) {
  const { push } = useHistory();
  const { planId } = useParams<{ planId: string }>();

  const {
    form,
    setForm,
    handleChange,
    handleCancel,
    onChanged: hasChange,
    setOnChanged: setHasChange,
  } = useHandleChange<RecurrencePlanDescriptionForm>(
    INITIAL_STATE,
    !!planData && {
      willUsePlanPage: planData.willUsePlanPage,
    }
  );

  function handleRadioChange(name: 'willUsePlanPage', value: boolean) {
    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));

    setHasChange(true);
  }

  function handleCancelButtonClick() {
    if (!hasChange) {
      return push('/recurrence/plan-management');
    }

    handleCancel();
  }

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: form,
    url: `/recurrence-plan/${planId}/description`,
    method: 'patch',
    authenticated: true,
    onSuccess: {
      redirect: {
        to: `/recurrence/plan-management/edit/${planId}/customization`,
      },
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  const location = window.location;
  const checkoutLink = `${location.origin}/recurrence-checkout/${planId}/personal-data`;

  return {
    form,
    isSubmitting,
    formValidation,
    hasChange,
    checkoutLink,
    handleChange,
    handleRadioChange,
    handleCancelButtonClick,
    onSubmit,
  };
}
