import dayjs from 'dayjs';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdNotificationsOutline as BellIcon } from 'react-icons/io';
import { MdFlagCircle } from 'react-icons/md';
import { useLocation } from 'react-router';
import { ROLE_LOCAL_STORAGE, TOKEN_LOCAL_STORAGE } from '../../../helpers/LocalStorageHelper';
import useFetch from '../../../hooks/useFetch';
import FilterButtons from './FilterButtons';
import NotificationIcon from './NotificationIcon';

import {
  Box,
  BoxProps,
  Button,
  Icon as ChakraIcon,
  Circle,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PlacementWithLogical,
  Select,
  Stack,
  Switch,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { axiosBackend } from '../../../api/Configuration';
import ErrorResponse from '../../../helpers/ErrorResponse';
import ProlunoNotification from '../../ProlunoNotification';

const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.300',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.400',
  },
};

function formatDate(date: string) {
  return dayjs(date).format('DD/MM/YYYY');
}

export type NotificationType =
  | 'feature'
  | 'sale'
  | 'lead'
  | 'task'
  | 'other'
  | 'news'
  | 'maintenance'
  | 'disclosure_invitation';

export interface Notification {
  id: number;
  title: string;
  createdAt: string;
  description: string;
  isRead?: boolean;
  type?: NotificationType;
}

interface NotificationsResponse {
  notificationUserData: Notification[];
  page: number;
  per_page: number;
  total: number;
  totalUnread: number;
}

function NotificationsDropDown({ ...props }: BoxProps) {
  const [filterDays, setFilterDays] = useState(30);
  const [showOnlyUnread, setShowOnlyUnread] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
  const [selectedProlunoNotification, setSelectedProlunoNotification] =
    useState<Notification | null>(null);
  const [filterType, setFilterType] = useState('');
  const [page, setPage] = useState(1);
  const [notificationsList, setNotificationsList] = useState<Notification[]>([]);
  const [notificationsResponse, setNotificationsResponse] = useState<NotificationsResponse>();
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingProlunoNotifications, setIsFetchingProlunoNotifications] = useState(false);
  const [hasMoreNotifications, setHasMoreNotifications] = useState(false);
  const [prolunoNotificationsList, setProlunoNotificationsList] = useState<Notification[]>([]);
  const isOwner = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'DONO';
  const toast = useToast();

  const { pathname } = useLocation();
  const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const menuPlacement: PlacementWithLogical = useBreakpointValue({
    base: 'auto',
    md: 'bottom-end',
  });

  const isAuthenticated = Boolean(localStorage.getItem(TOKEN_LOCAL_STORAGE));
  const isStudent = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'ALUNO';

  const {
    isOpen: isOpenProlunoNotification,
    onOpen: onOpenProlunoNotification,
    onClose: onCloseProlunoNotification,
  } = useDisclosure();

  const shouldOpenProlunoNotification =
    prolunoNotificationsList?.length > 0 &&
    selectedProlunoNotification &&
    !isFetchingProlunoNotifications;

  useEffect(() => {
    if (shouldOpenProlunoNotification) {
      onOpenProlunoNotification();
    }
  }, [onOpenProlunoNotification, shouldOpenProlunoNotification]);

  const { fetchData: markNotificationAsRead } = useFetch({
    url: `/notification/${selectedNotification?.id}/view`,
    method: 'post',
    autoFetch: false,
    authenticated: true,
  });

  const { fetchData: markAllNotificationsAsRead } = useFetch({
    url: '/notification/view-all',
    method: 'post',
    autoFetch: false,
    authenticated: true,
  });

  const viewNotification = useCallback(async () => {
    try {
      setIsFetchingProlunoNotifications(true);

      await axiosBackend().post(`/notification/${selectedProlunoNotification?.id}/view`);

      const restUnreadProlunoNotifications = prolunoNotificationsList?.filter(
        notification => notification.id !== selectedProlunoNotification?.id
      );

      setProlunoNotificationsList(restUnreadProlunoNotifications);
      setSelectedProlunoNotification(restUnreadProlunoNotifications[0] || null);
      onCloseProlunoNotification();
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao marcar a notificação como lida',
        description: ErrorResponse(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsFetchingProlunoNotifications(false);
    }
  }, [
    onCloseProlunoNotification,
    prolunoNotificationsList,
    selectedProlunoNotification?.id,
    toast,
  ]);

  const fetchProlunoNotifications = useCallback(async () => {
    const params = {
      type: 'MANAGER_SIP',
      hasPaginate: false,
    };

    try {
      setIsFetchingProlunoNotifications(true);

      const { data: response } = await axiosBackend().get(`/notification`, { params });
      const notifications = response?.data?.notificationUserData || [];
      const unreadProlunoNotifications = notifications.filter(notification => !notification.isRead);
      setProlunoNotificationsList(unreadProlunoNotifications);
      setSelectedProlunoNotification(unreadProlunoNotifications[0]);
    } finally {
      setIsFetchingProlunoNotifications(false);
    }
  }, []);

  useEffect(() => {
    if (isOwner) {
      fetchProlunoNotifications();
    }
  }, [fetchProlunoNotifications, isOwner]);

  const fetchNotifications = useCallback(async () => {
    const params = {
      ...(page && { page }),
      ...(filterType && { typeNotification: filterType }),
    };

    try {
      setIsFetching(true);

      const { data: response } = await axiosBackend().get(`/notification`, { params });
      const notifications = response?.data?.notificationUserData || [];

      const limitDate = dayjs().subtract(filterDays, 'days').startOf('day');

      // Verifica se há mais notificações
      const hasMore =
        notifications.length > 0 &&
        notifications.some(notification => dayjs(notification.createdAt).isAfter(limitDate));

      setHasMoreNotifications(hasMore);

      setNotificationsResponse(response.data);

      setNotificationsList(prevState =>
        page > 1 ? [...prevState, ...notifications] : notifications
      );
    } finally {
      setIsFetching(false);
    }
  }, [filterDays, filterType, page]);

  useEffect(() => {
    if (!isAuthenticated) return;

    fetchNotifications();
  }, [fetchNotifications, isAuthenticated, pathname]);

  function handleCloseModal() {
    onCloseModal();
    setPage(1);
    fetchNotifications();
    setSelectedNotification(null);
  }

  useEffect(() => {
    if (!isModalOpen) return;
    if (selectedNotification?.isRead) return;
    markNotificationAsRead();
  }, [isModalOpen, markNotificationAsRead, selectedNotification?.isRead]);

  function toggleIsShowingOnlyUnread() {
    setShowOnlyUnread(!showOnlyUnread);
  }

  function handleDaysFilterChange(id: number) {
    setFilterDays(Number(id));
    setPage(1);
  }

  function handleFilterTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setFilterType(event.target.value);
    setPage(1);
  }

  function handleNotificationClick(notification: Notification) {
    setSelectedNotification(notification);
    onOpenModal();
  }

  async function handleMarkAllNotificationsAsRead() {
    await markAllNotificationsAsRead();
    fetchNotifications();
  }

  function handleLoadMoreNotifications() {
    setPage(prevState => prevState + 1);
  }

  const filteredNotifications = useMemo(() => {
    const limitDate = dayjs().subtract(filterDays, 'days').startOf('day');

    return notificationsList?.filter(notification => {
      const isWithinDateRange = dayjs(notification.createdAt).isAfter(limitDate);
      const isUnread = !notification.isRead || !showOnlyUnread;
      const matchesType = filterType === '' || notification.type === filterType;

      return isWithinDateRange && isUnread && matchesType;
    });
  }, [filterDays, filterType, notificationsList, showOnlyUnread]);

  const hasSomeUnreadNotification = useMemo(() => {
    if (!notificationsList?.length) return false;

    return notificationsList.some(notification => !notification.isRead);
  }, [notificationsList]);

  const isLoadMoreButtonVisible = hasMoreNotifications && filteredNotifications.length >= 15;

  if (!isAuthenticated || isStudent) return;

  return (
    <Box position="relative" {...props}>
      <Menu placement={menuPlacement}>
        <MenuButton
          as={IconButton}
          aria-label="Notifications"
          icon={<BellIcon />}
          variant="ghost"
          color="secondary.500"
          sx={{ svg: { boxSize: 8 } }}
          _hover={{ bg: 'none' }}
          _expanded={{ bg: 'none' }}
        />

        <MenuList
          pt={5}
          pb={0}
          w={{ base: '92vw', md: 'xl' }}
          h="80vh"
          overflowY="auto"
          sx={scrollbarStyle}
          zIndex={9999}
          mt={{ base: 1, md: 'initial' }}
          mr={{ base: 4, md: 'initial' }}
          boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
          display="flex"
          flexDirection="column"
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
            justify="space-between"
            px={5}
          >
            <Heading size="md" m={0}>
              Notificações
            </Heading>

            <FormControl
              w={{ base: 'full', md: 'auto' }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel mb="0" fontSize="sm" color="gray.500">
                Mostrar apenas não lidas
              </FormLabel>
              <Switch colorScheme="primary" onChange={toggleIsShowingOnlyUnread} />
            </FormControl>
          </Stack>

          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
            align={{ base: 'column', md: 'start' }}
            spacing={{ base: 4, md: '5rem' }}
            mt={6}
            px={5}
          >
            <FilterButtons value={filterDays} onChange={handleDaysFilterChange} />

            <Stack
              direction={{ base: 'row', md: 'column' }}
              align={{ base: 'column', md: 'end' }}
              justify={{ base: 'space-between', md: 'end' }}
              w="100%"
            >
              <Select
                size={{ base: 'xs', md: 'sm' }}
                maxWidth={{ base: '48%', md: 'initial' }}
                focusBorderColor="gray.200"
                borderRadius="base"
                onChange={handleFilterTypeChange}
              >
                <option value="" hidden>
                  Selecione
                </option>

                <option value="sale">Vendas</option>
                <option value="lead">Novo aluno</option>
                <option value="affiliate">Vendas de Afiliado</option>
                <option value="task">Envio de Correção de Tarefas</option>
                <option value="feature">Funcionalidade/Avisos</option>
                <option value="">Todas</option>
              </Select>

              {hasSomeUnreadNotification && (
                <Button
                  onClick={handleMarkAllNotificationsAsRead}
                  variant="link"
                  colorScheme="gray"
                  size="xs"
                >
                  Marcar todas como lidas
                </Button>
              )}
            </Stack>
          </Stack>

          <VStack mt={5} spacing={4} align="end" flex={1} position="relative">
            {!notificationsResponse?.totalUnread && showOnlyUnread && (
              <VStack alignSelf="center" position="absolute" inset={0}>
                <ChakraIcon as={MdFlagCircle} w={32} h={32} color="gray.500" opacity={0.16} />

                <VStack spacing={0} align="center" color="gray.500">
                  <Text fontSize="sm" as="strong" fontWeight="medium">
                    Parabéns!
                  </Text>
                  <Text fontSize="sm">Todas as mensagens foram lidas.</Text>
                </VStack>
              </VStack>
            )}

            {filteredNotifications?.map(notification => (
              <MenuItem
                onClick={() => handleNotificationClick(notification)}
                key={notification.id}
                minH="48px"
                px={5}
              >
                <HStack w="full" align="start" spacing={3}>
                  <HStack>
                    <Circle
                      visibility={notification.isRead ? 'hidden' : 'visible'}
                      bg="blue.400"
                      size={1.5}
                    />
                    <NotificationIcon type={notification.type} />
                  </HStack>

                  <HStack align="start" justify="space-between" w="full" spacing={8}>
                    <VStack align="start">
                      <VStack align="start" spacing={0}>
                        <Text fontWeight={600}>{notification.title}</Text>

                        <Text
                          fontSize="sm"
                          noOfLines={2}
                          color="gray.500"
                          dangerouslySetInnerHTML={{ __html: notification.description }}
                        />
                      </VStack>

                      <Text mt={3} fontSize="xs" color="gray.400">
                        {dayjs(notification.createdAt).fromNow()}
                      </Text>
                    </VStack>

                    <Text fontSize="xs" color="gray.400">
                      {formatDate(notification.createdAt)}
                    </Text>
                  </HStack>
                </HStack>
              </MenuItem>
            ))}

            {isLoadMoreButtonVisible && (
              <Button
                w="100%"
                py={6}
                margin="auto"
                onClick={handleLoadMoreNotifications}
                variant="ghost"
                colorScheme="gray"
                size="sm"
                isLoading={isFetching}
              >
                Carregar mais
              </Button>
            )}
          </VStack>
        </MenuList>
      </Menu>

      <Circle
        size={4}
        position="absolute"
        top="-12%"
        left="48%"
        bgColor="secondary.500"
        color="primary.500"
        p={{ base: 1, lg: 2.5 }}
      >
        {notificationsResponse?.totalUnread > 99 ? (
          <Text fontSize={7} fontWeight={600}>
            99+
          </Text>
        ) : (
          <Text fontSize={{ base: 8, lg: 10 }} fontWeight={600}>
            {notificationsResponse?.totalUnread || '0'}
          </Text>
        )}
      </Circle>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader maxWidth="96%">{selectedNotification?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            pb={5}
            dangerouslySetInnerHTML={{ __html: selectedNotification?.description }}
            sx={{
              a: {
                color: '#005EBC',
                wordBreak: 'break-word',
                fontWeight: 'medium',
                _hover: { color: '#0075E0' },
              },
            }}
          />
        </ModalContent>
      </Modal>

      <ProlunoNotification
        isOpen={isOpenProlunoNotification}
        onClose={onCloseProlunoNotification}
        isLoading={isFetchingProlunoNotifications}
        notification={selectedProlunoNotification}
        viewNotification={viewNotification}
      />
    </Box>
  );
}

export default memo(NotificationsDropDown);
