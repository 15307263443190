import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { Plan } from '../types';
import PlanTableRow from './PlanTableRow';

interface PlanTableProps extends TableContainerProps {
  planList: Plan[];
}

export default function CheckoutSettingsTable({ planList, ...rest }: PlanTableProps) {
  return (
    <TableContainer {...rest}>
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF' } }}>
            <Th maxWidth="6.6875rem">ID</Th>

            <Th maxWidth="13.5625rem" whiteSpace="break-spaces">
              Nome
            </Th>

            <Th maxWidth="7rem" whiteSpace="break-spaces">
              Data de criação
            </Th>

            <Th>Recorrência</Th>

            <Th maxWidth="8.5rem" whiteSpace="break-spaces">
              Forma de pagamento
            </Th>

            <Th maxWidth="6.6875rem" whiteSpace="break-spaces">
              Valor
            </Th>

            <Th maxWidth="6.6875rem" whiteSpace="break-spaces">
              Status
            </Th>

            <Th textAlign="center">Ações</Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {planList?.map(plan => (
            <PlanTableRow key={plan.id} plan={plan} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
