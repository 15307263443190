import { Stack } from '@chakra-ui/react';
import { ChangeEvent, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SearchInput from './components/SearchInput';
import SortSelect from './components/SortSelect';
import StatusSelect from './components/StatusSelect';

export default function SubscriberManagementFilters() {
  const location = useLocation();
  const history = useHistory();
  const planName = (location.state as { planName: string })?.planName;

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  function updateUrlSearchParams(key: string, value: string | null) {
    if (value) {
      urlSearchParams.set(key, value);
    } else {
      urlSearchParams.delete(key);
    }
    history.push({ search: urlSearchParams.toString(), state: { planName } });
  }

  function handleSearchChange(value: string) {
    updateUrlSearchParams('search', value || null);
  }

  function handleOrderByChange(event: ChangeEvent<HTMLSelectElement>) {
    updateUrlSearchParams('orderBy', event.target.value || null);
  }

  function handleStatusChange(event: ChangeEvent<HTMLSelectElement>) {
    updateUrlSearchParams('status', event.target.value || null);
  }

  return (
    <Stack direction={{ base: 'column', xl: 'row' }} justify="space-between" w="100%">
      <SearchInput value="" onSearch={handleSearchChange} />

      <Stack direction={{ base: 'column', xl: 'row' }} w={{ base: '100%', xl: 'initial' }}>
        <SortSelect value={urlSearchParams.get('orderBy')} onChange={handleOrderByChange} />

        <StatusSelect value={urlSearchParams.get('status')} onChange={handleStatusChange} />
      </Stack>
    </Stack>
  );
}
