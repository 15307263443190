import {
  Box,
  Button,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa6';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { Notification, NotificationType } from '../ContainerSite/NotificationsDropDown';
import FullPageLoading from '../FullPageLoading';

interface ProlunoNotificationProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  notification: Notification;
  viewNotification: () => void;
}

function ConvertNotificationType(type: NotificationType) {
  switch (type) {
    case 'news':
      return 'Novidades';
    case 'maintenance':
      return 'Manutenção';
    case 'disclosure_invitation':
      return 'Convite/Divulgação';
  }
}

export default function ProlunoNotification({
  isOpen,
  onClose,
  isLoading,
  notification,
  viewNotification,
}: ProlunoNotificationProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      size={{ base: 'sm', md: 'xl' }}
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent as="form" padding="24px">
        <ModalHeader padding="0 0 15px 0">
          <HStack>
            <Circle bg="primary.500" size="30px">
              <IoMdNotificationsOutline color="white" />
            </Circle>

            <Text fontSize="18px" fontStyle="normal" fontWeight="600" lineHeight="28px">
              Notificação da Proluno
            </Text>
          </HStack>
        </ModalHeader>
        <ModalBody padding={0}>
          <Stack spacing="15px">
            <Stack spacing="5px" width="full">
              <Text fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="20px">
                Tipo:{' '}
                <Text as="span" fontWeight="400">
                  {ConvertNotificationType(notification?.type)}
                </Text>
              </Text>
              <Text fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="20px">
                Assunto:{' '}
                <Text as="span" fontWeight="400">
                  {notification?.title}
                </Text>
              </Text>
            </Stack>

            <Text dangerouslySetInnerHTML={{ __html: notification?.description }} />

            <Box width="full" display="flex" justifyContent="center">
              <Button
                leftIcon={<FaCheck />}
                colorScheme="primary"
                color="secondary.500"
                onClick={viewNotification}
              >
                Ciente
              </Button>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>

      <FullPageLoading isLoading={isLoading} />
    </Modal>
  );
}
