import { Image, Stack, useBreakpointValue } from '@chakra-ui/react';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import CookieBanner from '../../components/CookieBanner';
import FullPageLoading from '../../components/FullPageLoading';
import { useAuth } from '../../contexts/AuthContext';
import { UTMData } from '../../contexts/UTMContext';
import {
  TOKEN_LOCAL_STORAGE,
  TOKEN_PURCHASE,
  UTM_LOCAL_STORAGE,
} from '../../helpers/LocalStorageHelper';
import useFetch from '../../hooks/useFetch';
import StepBox from './Components/StepBox';
import Container from './Container';
import Payment from './Payment';
import PurchaseSummary from './PurchaseSummary';
import PurchaseSummaryMobile from './PurchaseSummaryMobile';
import Register from './Register';
import { RegisterSchemaType } from './Register/schemaValidator';
import CheckoutSkeleton from './Skeleton';

export type RecurrenceType =
  | 'daily'
  | 'biweekly'
  | 'monthly'
  | 'bimonthly'
  | 'quarterly'
  | 'semiannual'
  | 'yearly';
export type IParams = { idRecurrencePlan: string };
export type UserFormForPurchaseType = 'END' | 'TEL' | 'INS';
type ThankPageType = 'EXTERNAL' | 'DEFAULT';
type PaymentMethodType = 'credit_card';
type StatusType = 'ATIVO';

interface ICourse {
  id: number;
  name: string;
  thumbnail: string;
}

export interface IRecurrencePlanResponse {
  idRecurrencePlan: number;
  name: string;
  courses: ICourse[];
  value: string;
  paymentMethod: PaymentMethodType[];
  recurrence: RecurrenceType;
  willUsePlanPage: boolean;
  userFormForPurchase: UserFormForPurchaseType[] | null;
  topImage: string | null;
  bottomImage: string | null;
  thankPageType: ThankPageType;
  thankPageForApprovedPurchases: string | null;
  thankPageForPurchasesAwaitingPayment: string | null;
  thankPageForPurchasesAwaitingAnalysis: string | null;
  status: StatusType;
}
export interface IUserResponse {
  fullName: string;
  documentNumber: string;
  email: string;
  instagram: string;
  ddi: string;
  ddd: string;
  phone: string;
  address: {
    country: string;
    zipCode: string;
    streetAddress: string;
    streetNumber: string;
    neighborhood: string;
    state: string;
    city: string;
    complementary: string;
    alternateAddress: string;
  };
}

interface ICartAbandonment {
  courseId: number;
  totalPrice: number;
  paymentMethodType: string;
  lineItems: { product_name: string; quantity: number; price: number }[];
}

const BRAZILIAN_COUNTRIES = ['br', 'BR', 'Brazil', 'Brasil'];

const initialState: UTMData = {
  utm_id: null,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_term: null,
  utm_content: null,
};

export default function RecurrenceCheckout() {
  const [personalData, setPersonalData] = useState<Partial<RegisterSchemaType>>();
  const [country, setCountry] = useState('br');
  const location = useLocation();
  const utmDataLocalStorage = localStorage.getItem(UTM_LOCAL_STORAGE);
  const [utmData, setUtmData] = useState<UTMData>(initialState);
  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
  const tokenForPurchase = localStorage.getItem(TOKEN_PURCHASE);
  const [ddi, setDDI] = useState('55');
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const isDesktopScreen = useBreakpointValue({ base: false, md: false, lg: true, xl: true });

  const { idRecurrencePlan }: IParams = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: recurrencePlanResponse,
    error: recurrencePlansError,
    fetchData: getRecurrencePlan,
    loading: isLoading,
  } = useFetch<UnificadaFront.ResponseJSON<IRecurrencePlanResponse>>({
    url: `/recurrence-plan/${idRecurrencePlan}/show`,
    method: 'get',
    autoFetch: false,
  });

  const plan = recurrencePlanResponse?.data;

  useEffect(() => {
    if (!isAuthenticated && !tokenForPurchase) {
      return history.push({
        pathname: `/recurrence-checkout/${idRecurrencePlan}/personal-data`,
        search: window.location.search,
      });
    }
    if (!idRecurrencePlan) {
      return history.push({
        pathname: '/',
        search: window.location.search,
      });
    }
  }, [history, isAuthenticated, location.pathname, idRecurrencePlan, tokenForPurchase]);

  useEffect(() => {
    if (!utmDataLocalStorage) return;

    const utmData: {
      utm_id: string;
      utm_source: string;
      utm_medium: string;
      utm_campaign: string;
      utm_term: string;
      utm_content: string;
    } = JSON.parse(utmDataLocalStorage);

    setUtmData(utmData);
  }, [utmDataLocalStorage]);

  const {
    data: userResponse,
    fetchData: getUser,
    error,
  } = useFetch<UnificadaFront.ResponseJSON<IUserResponse>>({
    url: '/students/checkout',
    method: 'get',
    autoFetch: false,
    authenticated: true,
    token: token || tokenForPurchase,
  });

  useEffect(() => {
    if (token || tokenForPurchase) {
      getUser();
    }
  }, [getUser, token, tokenForPurchase]);

  useEffect(() => {
    if (error) {
      localStorage.removeItem(TOKEN_PURCHASE);
    }
  }, [error]);

  useEffect(() => {
    if (idRecurrencePlan) {
      getRecurrencePlan();
    }
    if (recurrencePlansError) {
      return history.push({
        pathname: '/',
      });
    }
  }, [getRecurrencePlan, recurrencePlansError, history, idRecurrencePlan]);

  const isSkeletonVisible = isLoading && !plan;
  const bannerTopDesktop = recurrencePlanResponse?.data?.topImage;
  const bannerBottomDesktop = recurrencePlanResponse?.data?.bottomImage;
  const userFormForPurchase = recurrencePlanResponse?.data?.userFormForPurchase;
  const thankPageType = recurrencePlanResponse?.data?.thankPageType;
  const thankPageForApprovedPurchases = recurrencePlanResponse?.data?.thankPageForApprovedPurchases;
  const isInternational = country && !BRAZILIAN_COUNTRIES.includes(country);

  const sendInfoCartAbandonmentPayload: ICartAbandonment = {
    courseId: parseInt(idRecurrencePlan),
    totalPrice: parseFloat(plan?.value),
    paymentMethodType: 'credit_card',
    lineItems: [
      {
        product_name: plan?.name,
        quantity: 1,
        price: parseFloat(plan?.value),
      },
    ],
  };

  const { fetchData: sendInfoCartAbandonment } = useFetch({
    data: sendInfoCartAbandonmentPayload,
    method: 'post',
    url: '/purchases/shopping-cart-abandonment',
    autoFetch: false,
    authenticated: true,
    token: token || tokenForPurchase,
  });

  const addressCountry = userResponse?.data?.address?.country;
  const addressVisible = userFormForPurchase?.includes('END');

  useEffect(() => {
    if (addressCountry && addressVisible) {
      setCountry(addressCountry);
    }
  }, [addressCountry, addressVisible]);

  useEffect(() => {
    if ((token || tokenForPurchase) && !!plan) {
      sendInfoCartAbandonment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, token, tokenForPurchase]);

  return (
    <Container>
      <Stack spacing="30px">
        <Image width="1010px" minH="1px" objectFit="cover" loading="lazy" src={bannerTopDesktop} />

        <Stack
          width="full"
          direction={{ base: 'column-reverse', md: 'column-reverse', lg: 'row', xl: 'row' }}
          alignItems="self-start"
          justifyContent="center"
          spacing="21px"
        >
          <Stack width="full" px={{ base: 4, md: 0, lg: 0, xl: 0 }}>
            <StepBox country={country} setCountry={setCountry} setDDI={setDDI} />
            <Switch>
              <Route
                path="/recurrence-checkout/:idRecurrencePlan/personal-data"
                location={{ search: window.location.search } as Location}
              >
                {isSkeletonVisible ? (
                  <CheckoutSkeleton />
                ) : (
                  <Register
                    ddi={ddi}
                    token={token}
                    country={country}
                    isInternational={isInternational}
                    userData={userResponse?.data}
                    userFormForPurchase={userFormForPurchase}
                    plan={plan}
                    setPersonalData={setPersonalData}
                  />
                )}
              </Route>
              <Route
                path="/recurrence-checkout/:idRecurrencePlan/payment"
                location={{ search: window.location.search } as Location}
              >
                {isSkeletonVisible ? (
                  <CheckoutSkeleton />
                ) : (
                  <Payment
                    plan={plan}
                    userData={userResponse?.data}
                    isLoading={isLoading}
                    utmData={utmData}
                    thankPageType={thankPageType}
                    thankPageForApprovedPurchases={thankPageForApprovedPurchases}
                    personalData={personalData}
                  />
                )}
              </Route>

              <Route
                path="/recurrence-checkout/:idRecurrencePlan"
                render={() => <Redirect to="/" />}
              />
            </Switch>
          </Stack>

          <Stack width="full">
            {isDesktopScreen ? (
              <PurchaseSummary plan={plan} isLoading={isLoading} />
            ) : (
              <Stack width="full" spacing={0} marginTop="-30px">
                <PurchaseSummaryMobile plan={plan} isLoading={isLoading} />
              </Stack>
            )}
          </Stack>
        </Stack>

        <Image
          width="1010px"
          minH="1px"
          objectFit="cover"
          loading="lazy"
          src={bannerBottomDesktop}
        />
      </Stack>

      <FullPageLoading isLoading={isLoading} />

      <CookieBanner />
    </Container>
  );
}
